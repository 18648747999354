import React from 'react';
import springboot from './logos/springboot.svg';
import angular from './logos/angular.svg';
import react from './logos/react.svg';
import postgres from './logos/postgres.svg';
import aws from './logos/aws2.svg';
import git from './logos/git.svg';
import {icon} from '@fortawesome/fontawesome-svg-core';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';


function App() {
    return (
        <div className="bg-cyan-950 text-white">
            <div className="max-w-screen-lg mx-auto h-screen relative grid place-items-center">
               <div className="">
                   <div className="w-full md:w-blue-block">
                       <div className="px-10 md:px-20 pt-24 pb-16 bg-cyan-600 rounded">
                           <h1 className="text-4xl md:text-6xl font-title-1">Novigado</h1>
                           <h2 className="mt-5 text-2xl md:text-3xl font-title-2">Freelance software development</h2>

                           <div className="mt-10 rounded py-3 px-6 inline-flex gap-7 bg-cyan-950 text-2xl ">
                               <a className="hover:text-cyan-600 transition-all" href="mailto:jeroen.verheyen@gmail.com">
                                   <FontAwesomeIcon icon={icon(faEnvelope)}/></a>
                               <a className="hover:text-cyan-600 transition-all"
                                  href="https://www.linkedin.com/in/jeroen-verheyen/"><FontAwesomeIcon
                                   icon={icon(faLinkedin)}/></a>
                           </div>

                       </div>
                   </div>

                   <div className=" inline-flex gap-3 md:gap-6 px-6 py-4 bg-cyan-100 -mt-8 mx-1 md:ml-3 rounded">
                       <img className="w-10 md:w-12" src={springboot} alt=""/>
                       <img className="w-10 md:w-12" src={angular} alt=""/>
                       <img className="w-10 md:w-12" src={react} alt=""/>
                       <img className="w-10 md:w-12" src={aws} alt=""/>
                       <img className="w-10 md:w-12" src={git} alt=""/>
                       <img className="w-10 md:w-12" src={postgres} alt=""/>
                   </div>

               </div>
                <div className="absolute bottom-3 right-3 text-sm text-cyan-700">
                    <div>icons by <a target="_blank" href="https://icons8.com">Icons8</a></div>
                </div>
            </div>
        </div>
    );
}

export default App;
